import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.css";
import styles2 from "../../pages/proformaUser/addProforma.module.css";
import Select from "react-select";
import { Button } from "react-bootstrap";
import {
  disabledFields,
  headerFields,
  RegisterNameValues,
  textFields,
} from "./FieldsArray";
import { updateAssetRow } from "../../service/api";
import { showNotification } from "../../service/showNotification";

export const AssetRowPopup = ({
  rowData,
  setIsEditClicked,
  fetchAssetsData,
}) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const [selectedOptions, setSelectedOptions] = useState({
    label: "Active",
    value: "Active",
  });
  
  const [selectedProdManager, setSelectedProdManager] = useState({
    label: "HDM",
    value: "HDM",
  });
  
  const [rowId, setRowId] = useState("");

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  
  const prodManagerOptions = [
    { label: "HDM", value: "HDM" },
    { label: "OMNIDIAN", value: "OMNIDIAN" },
  ];

  // Fields that should be excluded from the edit form entirely
  const excludedFields = [
    "Status", 
    "Prod Managed By",
    "Action", 
    "Created At", 
    "Updated At",
    "Record Id",
    "Asset Id",
    "Entity Name",
    "Client Name", 
    "Tranche Sold", 
    "Bill of Sale Signed"
  ];

  const onSubmit = async (values) => {
    try {
      const data = {
        ...values,
        status: selectedOptions.label,
        prodManagedBy: selectedProdManager.label,
      };

      await updateAssetRow(rowId, data);
      showNotification("success", "Values updated successfully");
      fetchAssetsData();
    } catch (error) {
      showNotification("error", "Something went wrong");
    } finally {
      setIsEditClicked(false);
    }
  };

  useEffect(() => {
    if (rowData && Object.keys(rowData).length) {
      setRowId(rowData?._id);
      
      // Set the status option based on the rowData
      if (rowData.status) {
        setSelectedOptions({
          label: rowData.status,
          value: rowData.status,
        });
      }
      
      // Set the prodManagedBy option based on the rowData
      if (rowData.prodManagedBy) {
        setSelectedProdManager({
          label: rowData.prodManagedBy,
          value: rowData.prodManagedBy,
        });
      } else {
        // Default to HDM if not set
        setSelectedProdManager({
          label: "HDM",
          value: "HDM",
        });
      }

      // Initialize form with values from rowData
      const formData = {};
      
      // Map database values to form fields
      headerFields.forEach((fieldName, index) => {
        const dbFieldName = RegisterNameValues[index];
        
        if (dbFieldName && !excludedFields.includes(fieldName)) {
          // Handle _id mapping to assetId
          if (dbFieldName === 'assetId') {
            formData[dbFieldName] = rowData._id || '';
          } 
          // Handle all other fields
          else {
            formData[dbFieldName] = rowData[dbFieldName] !== undefined ? rowData[dbFieldName] : '';
          }
        }
      });
      
      // Reset form with all the data
      reset(formData);
    }
  }, [rowData, reset]);

  return (
    <>
      <div className="overlay">
        <div>
          <div className="overlayContent" onClick={(e) => e.stopPropagation()}>
            <span className="closeBtn" onClick={() => setIsEditClicked(false)}>
              &times;
            </span>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.outerFormContainer}
            >
              <p className={styles2.heading}>Update Assets Details</p>
              <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>Status</label>
                <Select
                  options={statusOptions}
                  value={{
                    value: selectedOptions.value,
                    label: selectedOptions.label,
                  }}
                  onChange={(selected) => setSelectedOptions(selected)}
                />
              </div>
              
              <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>Prod Managed By</label>
                <Select
                  options={prodManagerOptions}
                  value={{
                    value: selectedProdManager.value,
                    label: selectedProdManager.label,
                  }}
                  onChange={(selected) => setSelectedProdManager(selected)}
                />
              </div>

              {headerFields.map((field, index) => {
                // Skip excluded fields
                if (excludedFields.includes(field)) return null;

                const mappedField = RegisterNameValues[index];
                if (!mappedField) return null; // Skip if no mapping exists
                
                const inputType = textFields.includes(field)
                  ? "text"
                  : "number";
                
                const isDisabled = disabledFields.includes(field);
                
                // For assetId field, use _id from rowData
                let fieldValue = mappedField === 'assetId' ? rowData?._id : rowData?.[mappedField];
                
                // Special handling for problematic fields to ensure they display correctly
                if (field === "Residential Customer Type") {
                  fieldValue = rowData?.residentialCustomerType || "";
                } else if (field === "Current PPA Signed Date") {
                  fieldValue = rowData?.currentPpaSignedDate || "";
                }
                
                return (
                  <div key={index} className={styles.inputContainer}>
                    <label className={styles2.labelStyle}>{field}</label>
                    <input
                      disabled={!!isDisabled}
                      {...register(mappedField)}
                      className={styles2.inputBox}
                      step="any"
                      placeholder={`Enter ${field}`}
                      type={inputType}
                      defaultValue={fieldValue || ""}
                    />
                  </div>
                );
              })}
              <div className={styles.inputContainer}>
                <Button type="submit" style={{ marginLeft: "auto" }}>
                  Edit Asset
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};