import { http } from "./http";

export const loginApi = async (body) => {
  const data = await http.post("/login", body);
  return data;
};

export const forgotPasswordApi = async (body) => {
  const data = await http.post("/forgot-password", body);
  return data;
};
export const resetPasswordApi = async (body) => {
  const data = await http.put("/reset-password", body);
  return data;
};

export const getSubadminApi = async (page = 1) => {
  const data = await http.get(`/subadmin?page=${page}&limit=${10}`);
  return data;
};

export const createSubadminApi = async (body) => {
  const data = await http.post("/subadmin", body);
  return data;
};

export const updateSubadminApi = async (id, body) => {
  const data = await http.put(`/subadmin/${id}`, body);
  return data;
};

export const deleteSubadminApi = async (id) => {
  const data = await http.delete(`/subadmin/${id}`);
  return data;
};
export const fetchFormData = async (model) => {
  const data = await http.get(`/constant?type=${model}`);
  return data;
};
export const sendFormData = async (body, modelId) => {
  const id = modelId;
  const data = await http.post(`/constant/${id}`, body);
  return data;
};
export const fetchStatesData = async () => {
  const data = await http.get(`/states`);
  return data;
};
export const sendStatesData = async (id, values) => {
  const data = await http.put(`/states/${id}`, values);
  return data;
};
export const verifyToken = async (token) => {
  const data = await http.get(`/verify-accesstoken/${token}`);
  return data;
};
export const subAdminProfile = async (body) => {
  const data = await http.put(`/profile-setup`, body);
  return data;
};
export const getReportData = async (currPage = 1, type, search) => {
  const data = await http.get(
    `/get-reports?page=${currPage}&limit=${10}&type=${type}&search=${search}`
  );
  return data;
};
export const generateReport = async () => {
  const data = await http.get(`/generate-report`);
  return data;
};

export const verifyAccount = async (zohoId) => {
  const data = await http.post(`/verify-account`, { zohoId });
  return data;
};

export const userReports = async (id, page) => {
  const data = await http.get(`/user-reports/${id}?page=${page}`);
  return data;
};

export const userInputs = async (id) => {
  const data = await http.get(`/user-input?id=${id}`);
  return data;
};

export const ReportDetails = async (id) => {
  const data = await http.get(`/detail-reports/${id}`);
  return data;
};

export const searchUser = async (query) => {
  const data = await http.get(`/user-list?search=${query}`);
  return data;
};

export const getUserInput = async (id) => {
  const data = await http.get(`/user-input?id=${id}`);
  return data;
};
export const updateUserInput = async (id, data) => {
  const userInputData = await http.put(`/user-input/${id}`, { data });
  return userInputData;
};
export const runReport = async (data) => {
  const userInputData = await http.post(`/report-generate`, data);
  return userInputData;
};

export const DownloadReport = async (id) => {
  const data = await http.get(`/download-report/${id}`, {
    responseType: "blob",
  });
  return data;
};

export const UpdateReport = async (body) => {
  const data = await http.post(`/report-updation`, body);
  return data;
};

export const DownloadStateMatrix = async () => {
  const data = await http.get(`/states/download`, {
    responseType: "blob",
  });
  return data;
};

export const CustomizationData = async () => {
  const data = await http.get(`/customization`);
  return data;
};

export const getNdaApi = async () => {
  const data = await http.get(`/nda`);
  return data;
};

export const updateNdaApi = async (body) => {
  const data = await http.put(`/nda`, body);
  return data;
};
export const updateUploadedStates = async (body) => {
  const data = await http.post(`/states/updateStates`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
export const getEntityList = async (currentPage, itemsPerPage) => {
  const data = await http.get(`/get-entity-setup?page=${currentPage}&limit=${itemsPerPage}`);
  return data.data;
};

export const changeIsAllowed = async (body) => {
  //not used
  const data = await http.patch(`/toggle-entity-info`, body);
  return data;
};

export const updateReply = async (id, body) => {
  const data = await http.patch(`/reply/${id}`, body);
  return data;
};

export const addToAllowList = async (body) => {
  const data = await http.post(`/add-allow-list`, body);
  return data;
};
export const downloadLicence = async (body) => {
  const data = await http.post(`/download-driving-licence`, body, {
    responseType: "blob",
  });
  return data;
};

export const getAllowList = async () => {
  const data = await http.get(`/get-allow-list`);
  return data;
};

export const removeAllowList = async (id) => {
  const data = await http.get(`/remove-allow-list/${id}`);
  return data;
};

export const updateStatus = async (body) => {
  const data = await http.patch(`/set-entity-status`, body);
  return data;
};
export const updateEntityComment = async (body) => {
  const data = await http.patch(`/update-comment`, body);
  return data;
};
export const uploadFile = async (body) => {
  const data = await http.post(`/upload-attachment`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

// purl flow
export const getPurlFlow = async () => {
  const data = await http.get(`/purl-flow`);
  return data;
};
export const updatePurlFlow = async (body) => {
  const data = await http.patch(`/purl-flow`, body);
  return data;
};

// Contact owner
export const getAllContactOwner = async (id) => {
  const data = await http.get(`/contact-owner`);
  return data.data;
};
export const getContactOwner = async (id) => {
  const data = await http.get(`/contact-owner/${id}`);
  return data.data;
};
export const updateContactOwner = async (body) => {
  const data = await http.put(`/contact-owner`, body);
  return data.data;
};
export const deleteContactOwner = async (id) => {
  const data = await http.delete(`/contact-owner/${id}`);
  return data.data;
};

// proforma values
export const getProformaUser = async () => {
  const data = await http.get(`/get-proforma-values`);
  return data;
};
export const updateProformaValues = async (body) => {
  const data = await http.patch(`/update-proforma-values`, body);
  return data;
};

// Proforma user
export const registerProformaUser = async (body) => {
  const data = await http.post(`/proforma-user`, body);
  return data;
};
export const getProformaUsers = async () => {
  const data = await http.get(`/proforma-users`);
  return data;
};
export const deleteProformaUser = async (id) => {
  const data = await http.delete(`/proforma-users/${id}`);
  return data;
};
export const editProformaUser = async (body) => {
  const data = await http.put(`/proforma-users`, body);
  return data;
};

// assets
export const getAllAssetsData = async (currentPage, itemsPerPage, searchTerm, dealName = 0, trancheTitle = 0) => {
  const data = await http.get(
    `/assets?page=${currentPage}&limit=${itemsPerPage}&searchTerm=${searchTerm}&dealName=${dealName}&trancheTitle=${trancheTitle}`
  );
  return data.data;
};
export const getAssetsOmnidian = async () => {
  const data = await http.get(`/assets/prod-managed-by/omnidian`);
  return data;
}
export const uploadAssets = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const data = await http.post(`/assets`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
export const uploadDuplicateAssets = async (body) => {
  const data = await http.post(`/add-duplicate-assets`, body);
  return data;
}
export const deleteAssets = async (id) => {
  const data = await http.delete(`/assets/${id}`);
  return data;
};
export const updateAssetRow = async (id, values) => {
  const data = await http.put(`/assets/${id}`, values);
  return data;
};

//baseline models
export const updateBaselineModelValues = async (values) => {
  const data = await http.put(`/baseline-model`, values);
  return data;
};
export const getBaselineModelValues = async (values) => {
  const data = await http.get(`/baseline-model`, values);
  return data;
};

// Contact group
export const createContactGroup = async (values) => {
  const data = await http.post(`/contact-group`, values);
  return data;
};
export const updateContactGroup = async (values) => {
  const data = await http.put(`/contact-group`, values);
  return data;
};
export const getContactGroup = async () => {
  const data = await http.get(`/contact-group`);
  return data;
};
export const deleteContactGroup = async (id) => {
  const data = await http.delete(`/contact-group/${id}`);
  return data;
};

export const updateContactGroupEmails = async (values) => {
  const data = await http.patch(`/contact-group`, values);
  return data;
};
export const deleteContactGroupEmails = async (id, values) => {
  const data = await http.delete(
    `/contact-group-email?id=${id}&email=${values}`
  );
  return data;
};
export const ContactGroupEmails = async (values) => {
  const data = await http.patch(`/contact-group`, values);
  return data;
};

export const getAllProductionReportsData = async (currentPage, itemsPerPage, searchTerm, dealName = 0, monthYear = 0) => {
  const data = await http.get(
    `/production-report?page=${currentPage}&limit=${itemsPerPage}&searchTerm=${searchTerm}&dealName=${dealName}&monthYear=${monthYear}`
  );
  return data.data;
};

// Upload production report
export const uploadProductionReport = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const data = await http.post(`/production-report`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

// Handle duplicate production reports
export const uploadDuplicateProductionReport = async (reports) => {
  const data = await http.post(`/add-duplicate-production-report`, { reports });
  return data;
};

// Update production report
export const updateProductionReportRow = async (id, values) => {
  const data = await http.put(`/production-report/${id}`, values);
  return data;
};

// Delete production report
export const deleteProductionReport = async (id) => {
  const data = await http.delete(`/production-report/${id}`);
  return data;
};

export const uploadSpecialReport = async (dealName, assetId, files) => {
  const formData = new FormData();
  formData.append('deal_name', dealName);
  formData.append('asset_id', assetId);
  files.forEach(file => formData.append('file', file));

  const response = await http.post('/production-report/special-uploads', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;

};

//permission
export const getUserPermission = async () => {
  const data = await http.get(`/permission/user`);
  return data.data;
};

export const createAdminPermission = async (data) => {
  const res = await http.post(`/permission`, data);
  return res;
}

export const getAllAdminTasks = async () => {
  const res = await http.get(`/permission/task`);
  return res;
}

export const getAdminPermissionByUserIdAndTaskId = async (userId, taskId) => {
  const res = await http.get(`/permissions?userId=${userId}&taskId=${taskId}`);
  return res;
}

export const updateAdminPermission = async (id, payload) => {
  const res = http.put(`/permission/${id}`, payload);
  return res;
}


//Admin tasks api's:

export const createAdminTask = async (data) => {
  const res = await http.post(`/permission/task`, data);
  return res;
}

export const deleteAdminTask = async (id) => {
  const res = await http.delete(`/permission/task/${id}`)
  return res;
}