import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { getAssetsOmnidian, uploadSpecialReport } from "../../service/api";
import { FaUpload } from "react-icons/fa";
import styles from "./SpecialUploadPopup.module.css";
import { showNotification } from "../../service/showNotification";

const SpecialUploadPopup = ({
  isSpecialUploadModalOpen,
  closeSpecialUploadModal,
  refreshTable,
}) => {
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedDeal, setSelectedDeal] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAssetsOmnidian();
        const data = response.data.data || [];
        setOptions(data);
        setFilteredOptions(data);
      } catch (error) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleOptions = () => setShowOptions((prev) => !prev);
  console.log("selectedDeal", selectedFiles);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOptions(
      options.filter((option) => option.dealName.toLowerCase().includes(query))
    );
    setShowOptions(true);
  };

  const handleSelectDeal = (deal) => {
    console.log("hi");

    // setSearchQuery(deal.dealName);
    setSelectedDeal(deal);
    // Automatically trigger file selection when clicking deal name
    document.getElementById(`file-input-${deal._id}`).click();
  };

  const handleFileChange = (e, assetId) => {
    e.stopPropagation();
    console.log("handleFileChange");

    const files = Array.from(e.target.files);

    setSelectedFiles({
      [assetId]: files,
    });

    const selectedDeal = options.find((option) => option._id === assetId);
    if (selectedDeal) {
      setSearchQuery(selectedDeal?.dealName);
      setSelectedDeal(selectedDeal);
    }

    setShowOptions(false);
    e.preventDefault();
  };

  const handleUpload = async () => {
    if (!selectedDeal) return;
    if (!selectedFiles[selectedDeal?._id]?.length) return;

    setUploading(true);

    try {
      const response = await uploadSpecialReport(
        selectedDeal.dealName,
        selectedDeal._id,
        selectedFiles[selectedDeal?._id]
      );

      if (!response?.data?.success)
        showNotification("success", "Files uploaded successfully!");
      setSelectedFiles({});
      setSelectedDeal(null);
      setSearchQuery("");
      refreshTable();
      closeSpecialUploadModal();
    } catch (error) {
      showNotification(
        "error",
        error.response?.data?.message || "Upload failed"
      );
    } finally {
      setUploading(false);
    }
  };

  const renderLoading = () => (
    <div className={styles.modalCentered}>
      <Spinner animation="border" />
    </div>
  );

  const renderOptionsDropdown = () => {
    if (!showOptions) return null;

    return (
      <div className={`${styles.optionsDropdown} ${styles.showOptions}`}>
        {filteredOptions.length === 0 ? (
          <p style={{ padding: "10px" }}>No deals found.</p>
        ) : (
          filteredOptions.map((option) => (
            <div
              key={option._id}
              className={styles.optionItem}
              onClick={() => handleSelectDeal(option)}
            >
              <span>{option.dealName}</span>

              <label onClick={(e) => e.stopPropagation()}>
                <input
                  id={`file-input-${option._id}`}
                  type="file"
                  accept=".csv"
                  multiple
                  onChange={(e) => handleFileChange(e, option._id)}
                  className={styles.fileInput}
                />

                <FaUpload className={styles.uploadIcon} />
              </label>
            </div>
          ))
        )}
      </div>
    );
  };

  const renderSelectedFiles = () => {
    if (!selectedDeal) return;
    if (!selectedFiles[selectedDeal._id]?.length) return;

    return (
      <div className={styles.selectedFilesContainer}>
        <div>
          <strong>{selectedDeal.dealName}: </strong>
          <span>
            {selectedFiles[selectedDeal._id]?.length || 0} file(s) selected
          </span>
        </div>
        <ul className={styles.ul}>
          {selectedFiles[selectedDeal._id]?.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Modal
      centered
      show={isSpecialUploadModalOpen}
      onHide={() => {
        setSelectedDeal(null);
        setSearchQuery("");
        setSelectedFiles(null);
        closeSpecialUploadModal(false);
        closeSpecialUploadModal(false);
      }}
      className={styles.wideModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Special Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          renderLoading()
        ) : (
          <>
            <div className={styles.selectBox} onClick={toggleOptions}>
              <input
                type="text"
                placeholder="Search or Select a Deal"
                value={searchQuery}
                onChange={handleSearch}
                className={styles.searchInput}
              />
            </div>

            {renderOptionsDropdown()}
            {renderSelectedFiles()}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleUpload}
          disabled={
            uploading ||
            !selectedDeal ||
            !selectedFiles[selectedDeal?._id]?.length
          }
          className={styles.uploadButton}
        >
          {uploading ? <Spinner size="sm" animation="border" /> : "Upload"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SpecialUploadPopup;

/*

User will upload multiple files or a single file for each asset that shows up in special uploads. It will need to create a matching record similar to the primary records that are created from the master upload of production and make them look similar. Record ID and Record ID deal will be Null, Assets ID will come from the tied ID from Assets Module, Deal Name will also COME FROM ASSETS module (Unlike the master upload that provides one), Month year found in the reports uploaded, measurement type is always "Metered" Actual production comes from "Actual Measured Energy (kWh)" in the Omnidian Report, Net estimate comes from "Expected Energy (kWh)" in the Omnidian Report, and Actual / Estimated % will be a calculation of Actual/Expected. In the even actual is zero, handle the error and report 0 in the division. When multiple files are uploaded at once, the system will need to COMBINE each Month/Year row into a single record using summing across all reports uploaded at once. Each time a special upload is done, it will reset all records for that asset and create them again due to the fact that it can't match to a record id like the master upload in order to avoid any duplication.

*/
