export const RegisterNameValues = [
  "status",
  "prodManagedBy",
  "createdAt",
  "updatedAt",
  "recordId",
  "assetId",
  "dealName",
  "assetOwner",
  "assetStage",
  "financingOption",
  "systemSize",
  "ppaTerm",
  "epcAmount",
  "deposit",
  "inceptionPurchasePrice",
  "inceptionEquityContribution",
  "investorPurchasePrice",
  "investorEquityContribution",
  "inceptionFinalMarkupPercentage",
  "inceptionFinalMarkup",
  "developerPayment",
  "developerMarkupCheck",
  "inverterWarranty",
  "investorPurchasePriceFinal",
  "investorEquityContributionFinal",
  "adminFee",
  "costPerKwh",
  "trancheTitle",
  "dealSource",
  "assetType",
  "year1Production",
  "productionGuarantee",
  "electricityOffsetPercentage",
  "fullName",
  "dealType",
  "installCompletedDate",
  "m1CertificateSignDate",
  "energizedDate",
  "prePaidPercentageOfBillOfSale",
  "m2CertificateSignedDate",
  "moduleManufacturerAVL",
  "inverterManufacturerAVL",
  "recordNumber",
  "assetStreetAddress",
  "assetCity",
  "assetState",
  "assetZipCode",
  "initialTerm",
  "ppaRate",
  "estimatedExtendedWarrantyCost",
  "solarPrepaid",
  "batterySalesPrice",
  "batteryPrepaid",
  "billOfSaleAmount",
  "censusTractId",
  "costPerMonth",
  "customerCounty",
  "dealOwner",
  "energyCommunityDescription",
  "energyCommunityTaxEligible",
  "energyCommunityTitle",
  "energyCommunityType",
  "entityType",
  "installerCertifiedToInstallBatteries",
  "inverterManufacturer",
  "monitoringCommunication",
  "panelManufacturer",
  "partialOrFullBackup",
  "propertyTrustName",
  "ptoLetterDate",
  "residentialCustomerType",
  // "storageOnly",
  "annualDegradationPercentage",
  "batteryKwSize",
  "sowAndPpaApprovalDate",
  "currentPpaSignedDate",
  "powerCapacityRating",
  // Rearranged to match header order
  "entityName",
  "clientName",
  "trancheSold",
  "billOfSaleSigned"
];

export const headerFields = [
  "Status",
  "Prod Managed By",
  "Created At",
  "Updated At",
  "Record Id",
  "Asset Id",
  "Deal Name",
  "Asset Owner",
  "Asset Stage",
  "Financing Option",
  "System Size (kW)",
  "PPA Term",
  "EPC Amount",
  "Deposit",
  "Inception Purchase Price",
  "Inception Equity Contribution",
  "Investor Purchase Price",
  "Investor Equity Contribution",
  "Inception Final Markup [%]",
  "Inception Final Markup",
  "Developer Payment",
  "Developer Markup Check",
  "Inverter Warranty",
  "Investor Purchase Price",
  "Investor Equity Contribution",
  "Admin Fee",
  "Cost Per kWh.",
  "Tranche Title",
  "Deal Source",
  "Asset Type",
  "Year 1 Production",
  "Production Guarantee",
  "Electricity Offset (%)",
  "Full Name",
  "Deal Type",
  "Install Completed Date",
  "M1 Certificate Sign Date",
  "Energized Date",
  "Pre Paid Percentage of Bill of Sale",
  "M2 Certificate Signed Date",
  "Module Manufacturer(AVL)",
  "Inverter Manufacturer(AVL)",
  "Record Number",
  "Asset Street Address",
  "Asset City",
  "Asset State",
  "Asset Zip Code",
  "Initial Term kWh",
  "PPA Rate",
  "Estimated Extended Warranty Cost",
  "Solar Prepaid",
  "Battery Sales Price",
  "Battery Prepaid",
  "Bill of Sale Amount",
  "Census Tract ID",
  "Cost Per Month",
  "Customer County",
  "Deal Owner",
  "Energy Community Description",
  "Energy Community Tax Eligible",
  "Energy Community Title",
  "Energy Community Type",
  "Entity Type",
  "Installer Certified to Install Batteries",
  "Inverter Manufacturer",
  "Monitoring Communication",
  "Panel Manufacturer",
  "Partial or Full Backup",
  "Property Trust Name",
  "PTO Letter Date",
  "Residential Customer Type",
  // "Storage Only",
  "Annual Degradation %",
  "Battery Kwh Size",
  "SOW & PPA Approval Date",
  "Current PPA Signed Date",
  "Power Capacity Rating (kW)",
  // New fields added with special styling (including Entity Name)
  "Entity Name",
  "Client Name",
  "Tranche Sold",
  "Bill of Sale Signed",
  "Action",
];

// Fields that need special styling (dark green headers)
export const specialStylingFields = [
  "Client Name",
  "Entity Name", // Already in the table but needs styling
  "Tranche Sold",
  "Bill of Sale Signed"
];

export const disabledFields = [
  "Inception Purchase Price",
  "Inception Final Markup",
  "Investor Purchase Price",
  "Investor Equity Contribution",
  "Developer Payment",
  "Developer Markup Check",
  "Admin Fee",
  "PPA Rate",
  "Record Id",
  "Asset Id",
  // Adding new fields to disabled list since they shouldn't be editable
  "Client Name",
  "Tranche Sold",
  "Bill of Sale Signed",
];

export const textFields = [
  "Deal Name",
  "Asset Owner",
  "Asset Stage",
  "Financing Option",
  "Tranche Title",
  "Deal Source",
  "Asset Type",
  "Full Name",
  "Deal Type",
  "Install Completed Date",
  "M1 Certificate Sign Date",
  "Energized Date",
  "Pre Paid Percentage of Bill of Sale",
  "M2 Certificate Signed Date",
  "Module Manufacturer(AVL)",
  "Inverter Manufacturer(AVL)",
  "Asset Street Address",
  "Asset City",
  "Asset State",
  "Census Tract ID",
  "Customer County",
  "Deal Owner",
  "Energy Community Decription",
  "Energy Community Tax Eligible",
  "Energy Community Title",
  "Energy Community Type",
  "Entity Name",
  "Entity Type",
  "Installer Certified to Install Batteries",
  "Inverter Manufacturer",
  "Monitoring Communication",
  "Panel Manufacturer",
  "Partial or Full Backup",
  "Property Trust Name",
  "PTO Letter Date",
  "Residential Customer Type",  // Fixed spelling (was "Residiential")
  "Storage Only", 
  "SOW & PPA Approval Date",
  "Current PPA Signed Date",    // Added this field explicitly
  "Power Capacity Rating (kW)",
  "Prod Managed By",
  // New text fields
  "Client Name",
  "Tranche Sold",
  "Bill of Sale Signed"
];