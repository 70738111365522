import React, { useEffect, useState } from "react";
import styles from "../AccessForm/AccessForm.module.css";
import { Button } from "react-bootstrap";
import { getSubadminApi } from "../../service/api";
import Select from "react-select";
import { AddTaskModal } from "./AddTaskModal";

const Header = ({ fetchAdminTasks, selectedSubAdmin, setSelectedSubAdmin }) => {
  const [subAdminOptions, setSubAdminOptions] = useState([]);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);

  const handleSelectSubAdmin = (selectedOption) => {
    setSelectedSubAdmin(selectedOption);
  };

  const fetchSubAdmins = async () => {
    try {
      const response = await getSubadminApi();
      const subAdminData = response?.data?.data?.data || [];
      const options = subAdminData.map((item) => ({
        label: item.name,
        value: item._id,
      }));

      setSubAdminOptions(options);

      if (options.length > 0) {
        setSelectedSubAdmin(options[0]);
      }
    } catch (error) {
      console.error("Error fetching SubAdmins:", error);
    }
  };

  useEffect(() => {
    fetchSubAdmins();
  }, []);

  return (
    <>
      <h2 className={styles.heading}>Access Control</h2>

      <div className={styles.topContainer}>
        <Select
          options={subAdminOptions}
          value={selectedSubAdmin}
          onChange={handleSelectSubAdmin}
          styles={{
            control: (provided) => ({
              ...provided,
              padding: "3px",
              width: "250px",
            }),
          }}
        />

        <div className={styles.buttonContainer}>
          <Button className="mx-2" onClick={() => setIsAddTaskModalOpen(true)}>
            Add Task
          </Button>
        </div>
      </div>

      <AddTaskModal
        show={isAddTaskModalOpen}
        onClose={() => setIsAddTaskModalOpen(false)}
        onAddSuccess={fetchAdminTasks}
      />
    </>
  );
};

export default Header;
