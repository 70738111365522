import React from "react";
import { Button } from "react-bootstrap";

const DeleteConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  isLoading 
}) => {
  if (!isOpen) return null;

  return (
    <div className="overlay" onClick={onClose}>
      <div 
        className="overlayInnerContent"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="closeBtn p-2" onClick={onClose}>
          &times;
        </span>
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <div className="bg-white w-[90vw] max-w-lg p-3 rounded-lg">
            <h5 className="mb-4">
              Are you sure you want to <span className="warning">DELETE</span> this Production Report?
            </h5>
            <div className="overlayButton mt-4">
              <Button
                onClick={onConfirm}
                className="bg-[#356C58] text-white px-4 py-2 rounded-lg hover:bg-[#2f563d]"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "YES"
                )}
              </Button>
              <Button
                onClick={onClose}
                className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400"
              >
                NO
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;