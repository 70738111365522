import React from "react";
import styles from "../AccessForm/AccessForm.module.css";
import {
  updateAdminPermission,
  createAdminPermission,
} from "../../service/api.js";
import { showNotification } from "../../service/showNotification.js";
import PermissionToggle from "./PermissionToggle";

const PermissionsPanel = ({
  toggleState,
  selectedSubAdmin,
  selectedNavItem,
  adminPermissionId,
  onToggleChange,
}) => {
  const handleToggleChange = async (perm) => {
    if (!selectedSubAdmin || !selectedNavItem?.task) {
      console.error("Missing SubAdmin ID or Task ID");
      return;
    }

    const updatedState = { ...toggleState, [perm]: !toggleState[perm] };
    const userId = selectedSubAdmin?.value || "";

    const payload = {
      user: userId,
      task: selectedNavItem.task,
      permissions: {
        create: !!updatedState.create,
        read: !!updatedState.read,
        update: !!updatedState.update,
        delete: !!updatedState.delete,
      },
    };

    try {
      let response;
      if (adminPermissionId) {
        response = await updateAdminPermission(adminPermissionId, payload);
      } else {
        response = await createAdminPermission(payload);
      }

      if (response.data.success) {
        showNotification("success", response.data.message);
        onToggleChange(updatedState);
      } else {
        showNotification("error", response.data.message);
      }
    } catch (error) {
      console.error(
        "Error updating permissions:",
        error?.response?.data || error
      );
    }
  };

  return (
    <div className={styles.right}>
      <div
        className={`${styles.toggleCard} 
          ${!selectedSubAdmin || !selectedNavItem ? styles.disabledCard : ""}`}
      >
        <div className={styles.permissionsColumn}>
          {["create", "read", "update", "delete"].map((perm) => (
            <PermissionToggle
              key={perm}
              permission={perm}
              isChecked={toggleState[perm]}
              onChange={() => handleToggleChange(perm)}
              disabled={!selectedSubAdmin || !selectedNavItem}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PermissionsPanel;
