import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingUsd,
  faSignOutAlt,
  faTable,
  faTimes,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import InceptionPic from "../assets/img/logo.png";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import useLocalStorage from "../hooks/useLocalStorage";
import { usePermissions } from "../context/PermissionContext";

export default (props = {}) => {
  const [name, _] = useLocalStorage("name");
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const history = useHistory();
  
  const { hasPermission,resetPermissions } = usePermissions();
  const onCollapse = () => setShow(!show);

  const handleLogout = () => {
    resetPermissions();
    localStorage.removeItem("token");
    history.push(Routes.Signin.path);
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      permission,
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    const shouldShowNavItem = title === "Inception Financial" || !permission || hasPermission(permission);
    return (
     shouldShowNavItem && (
        <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
          <Nav.Link {...linkProps} target={target} className={classNames}>
            <span>
              {icon ? (
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
              ) : null}
              {image ? (
                <Image
                  src={image}
                  width={20}
                  height={20}
                  className="sidebar-icon svg-icon"
                />
              ) : null}

              <span className="sidebar-text">{title}</span>
            </span>
            {badgeText ? (
              <Badge
                pill
                bg={badgeBg}
                text={badgeColor}
                className="badge-md notification-count ms-2"
              >
                {badgeText}
              </Badge>
            ) : null}
          </Nav.Link>
        </Nav.Item>
      )
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={InceptionPic} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, {name}</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                    onClick={() => handleLogout()}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                title="Inception Financial"
                link={Routes.DashboardOverview.path}
                image={InceptionPic}
              />

              <NavItem
                title="SubAdmin List"
                icon={faUsers}
                link={Routes.SubAdmin.path}
                permission={"read:subadmin_list"}
              />
              <NavItem
                title="Customization Section"
                icon={faTable}
                link={Routes.Customization.path}
                permission={"read:customization_section"}
              />
              <NavItem
                title="Report List"
                icon={faHandHoldingUsd}
                link={Routes.Transactions.path}
                permission={"read:report_list"}
              />
              {/* <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} /> */}
              <NavItem
                title="Project Input"
                icon={faTable}
                link={Routes.ProjectInput.path}
                permission={"read:project_input"}
              />
              <NavItem
                title="Entity Table"
                icon={faTable}
                link={Routes.EntityTable.path}
                permission={"read:entity_table"}
              />

              <NavItem
                title="Proforma Backend"
                icon={faTable}
                link={Routes.BootstrapTables.path}
                permission={"read:proforma_backend"}
              />
              {/* </CollapsableNavItem> */}
              <NavItem
                title="New Reports"
                icon={faTable}
                link={Routes.NewReports.path}
                permission={"read:new_reports"}
              />
              <NavItem
                title="NDA Form"
                icon={faTable}
                link={Routes.NDAForm.path}
                permission={"read:nda_form"}
              />
              <NavItem
                title="PURL Journey"
                icon={faTable}
                link={Routes.PURLJourney.path}
                permission={"read:purl_journey"}
              />
              <NavItem
                title="Proforma Users"
                icon={faTable}
                link={Routes.ProformaUsers.path}
                permission={"read:proforma_users"}
              />
              <NavItem
                title="Contact Owner"
                icon={faTable}
                link={Routes.ContactOwner.path}
                permission={"read:contact_owner"}
              />
              <NavItem
                title="Assets"
                icon={faTable}
                link={Routes.Assets.path}
                permission={"read:assets"}
              />
              <NavItem
                title="Production Reporting"
                icon={faTable}
                link={Routes.ProductionReporting.path}
                permission={"read:production_reporting"}
              />

              <NavItem
              title="Access Control"
              icon={faTable}
              link={Routes.AccessControl.path}
              permission={"read:access_control"}
              />

              <Dropdown.Divider className="my-3 border-indigo" />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
