import React, { useCallback, useEffect, useState } from "react";
import { Card, Nav, Pagination, Table } from "react-bootstrap";
import { TableSkeleton } from "../../skeleton/TableSkeleton";
import { deleteAssets, getAllAssetsData } from "../../service/api";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { showNotification } from "../../service/showNotification";
import { AssetRowPopup } from "./AssetRowPopup";
import { headerFields, specialStylingFields } from "./FieldsArray";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowUnsorted } from "react-icons/ti";
import { showPageNumbers } from "../../utils/table";
import { formatDate } from "../../utils/formatDate";

const AssetsTable = ({ isNewUploaded, searchTerm, setTotalAssets }) => {
  const [loading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(10);
  const [totalPages, setTotalPages] = useState(10);
  const [rowData, setRowData] = useState({});
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [sort, setSort] = useState({
    "Deal Name": 0,
    "Tranche Title": 0
  });
  const itemsPerPage = 10;
  const showPaginationNumbers = totalPages > 10;

  const deleteStyles = {
    marginRight: "10px",
    color: "red",
    fontSize: "1.2rem",
  };
  const EditStyles = {
    color: "green",
    fontSize: "1.2rem",
  };
  
  // Style for special header fields
  const specialHeaderStyle = {
    color: "#356C58",  // Dark green color for text (same as the edit button)
    fontWeight: "bold"
  };


  const fetchAssetsData = useCallback(async () => {
    try {
      const response = await getAllAssetsData(currentPage, itemsPerPage, searchTerm, sort["Deal Name"], sort["Tranche Title"]);
      setAssets(response.data.assets);
      const totalPages = Math.ceil(response.data.totalDocs / itemsPerPage);
      setTotalDocs(response.data.totalDocs);
      setTotalPages(totalPages);
      setTotalAssets(response.data.totalDocs);
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, searchTerm, sort, setTotalAssets]);

  const handleDelete = async (id) => {
    try {
      await deleteAssets(id);
      showNotification("success", "Row deleted successfully");
      fetchAssetsData();
    } catch (error) {
      showNotification("Something went wrong");
    }
  };
  
  const handleEdit = async (item) => {
    setRowData(item);
    setIsEditClicked(true);
  };

  const changeOrder = (header) => {
    const newOrder = sort[header] === 0 ? 1 : sort[header] === 1 ? -1 : 1;
    if(header === "Deal Name"){
      setSort({"Deal Name": newOrder, "Tranche Title": 0})
    }
    else{
      setSort({"Deal Name": 0, "Tranche Title": newOrder})
    }
  };

  // Function to check if a header needs special styling
  const isSpecialHeader = (header) => {
    return specialStylingFields.includes(header);
  };

  useEffect(() => {
    fetchAssetsData();
  }, [currentPage, isNewUploaded, fetchAssetsData, searchTerm, sort]);

  return (
    <>
      {loading ? (
        <TableSkeleton />
      ) : (
        <div className="scrollable-table-container2 mx-3">
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    {headerFields.map((header, index) => {
                      const isSortApplied = header === "Deal Name" || header === "Tranche Title";
                      const sortIcon = sort[header] === 0 ? <TiArrowUnsorted color="grey" size={16} /> : sort[header] === 1 ? <TiArrowSortedDown size={16} /> : <TiArrowSortedUp size={16} />;
                      
                      // Apply special styling for the specified header fields
                      const headerStyle = isSpecialHeader(header) ? specialHeaderStyle : {};
                      
                      return isSortApplied ? (
                        <th 
                          key={index} 
                          style={{
                            display: "flex", 
                            justifyContent: "space-between", 
                            padding: "14px 24px",
                            ...(isSpecialHeader(header) ? specialHeaderStyle : {})
                          }} 
                          onClick={() => changeOrder(header)}
                        >
                          {header}{sortIcon}
                        </th>
                      ) : (
                        <th key={index} style={headerStyle}>{header}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {assets?.map((item) => {
                    return (
                      <tr key={item._id}>
                        <th>{item?.status || "-"}</th>
                        <th>{item?.prodManagedBy || "HDM"}</th>
                        <th>{formatDate(new Date(item?.createdAt)) || ""}</th>
                        <th>{formatDate(new Date(item?.updatedAt)) || ""}</th>
                        <th className="assetInfo" title={item?.recordId || ''}>
                          Info
                        </th>
                        <th>{item?._id || "-"}</th>
                        <th>{item?.dealName || "-"}</th>
                        <th>{item?.assetOwner || "-"}</th>
                        <th>{item?.assetStage || "-"}</th>
                        <th>{item?.financingOption || "-"}</th>
                        <th>{item?.systemSize || "-"}</th>
                        <th>{item?.ppaTerm || "-"}</th>
                        <th>{item?.epcAmount || "-"}</th>
                        <th>{item?.deposit || "-"}</th>
                        <th>{item?.inceptionPurchasePrice || "-"}</th>
                        <th>{item?.inceptionEquityContribution || "-"}</th>
                        <th>{item?.investorPurchasePrice || "-"}</th>
                        <th>{item?.investorEquityContribution || "-"}</th>
                        <th>{item?.inceptionFinalMarkupPercentage || "-"}</th>
                        <th>{item?.inceptionFinalMarkup || "-"}</th>
                        <th>{item?.developerPayment || "-"}</th>
                        <th>{item?.developerMarkupCheck || "-"}</th>
                        <th>{item?.inverterWarranty || "-"}</th>
                        <th>{item?.investorPurchasePriceFinal || "-"}</th>
                        <th>{item?.investorEquityContributionFinal || "-"}</th>
                        <th>{item?.adminFee || "-"}</th>
                        <th>{item?.costPerKwh || "-"}</th>
                        <th>{item?.trancheTitle || "-"}</th>
                        <th>{item?.dealSource || "-"}</th>
                        <th>{item?.assetType || "-"}</th>
                        <th>{item?.year1Production || "-"}</th>
                        <th>{item?.productionGuarantee || "-"}</th>
                        <th>{item?.electricityOffsetPercentage || "-"}</th>
                        <th>{item?.fullName || "-"}</th>
                        <th>{item?.dealType || "-"}</th>
                        <th>{item?.installCompletedDate || "-"}</th>
                        <th>{item?.m1CertificateSignDate || "-"}</th>
                        <th>{item?.energizedDate || "-"}</th>
                        <th>{item?.prePaidPercentageOfBillOfSale || "-"}</th>
                        <th>{item?.m2CertificateSignedDate || "-"}</th>
                        <th>{item?.moduleManufacturerAVL || "-"}</th>
                        <th>{item?.inverterManufacturerAVL || "-"}</th>
                        <th>{item?.recordNumber || "-"}</th>
                        <th>{item?.assetStreetAddress || "-"}</th>
                        <th>{item?.assetCity || "-"}</th>
                        <th>{item?.assetState || "-"}</th>
                        <th>{item?.assetZipCode || "-"}</th>
                        <th>{item?.initialTerm || "-"}</th>
                        <th>{item?.ppaRate || "-"}</th>
                        <th>{item?.estimatedExtendedWarrantyCost || "-"}</th>
                        <th>{item?.solarPrepaid || "-"}</th>
                        <th>{item?.batterySalesPrice || "-"}</th>
                        <th>{item?.batteryPrepaid || "-"}</th>
                        <th>{item?.billOfSaleAmount || "-"}</th>
                        <th>{item?.censusTractId || "-"}</th>
                        <th>{item?.costPerMonth || "-"}</th>
                        <th>{item?.customerCounty || "-"}</th>
                        <th>{item?.dealOwner || "-"}</th>
                        <th>{item?.energyCommunityDescription || "-"}</th>
                        <th>{item?.energyCommunityTaxEligible || "-"}</th>
                        <th>{item?.energyCommunityTitle || "-"}</th>
                        <th>{item?.energyCommunityType || "-"}</th>
                        <th>{item?.entityType || "-"}</th>
                        <th>
                          {item?.installerCertifiedToInstallBatteries || "-"}
                        </th>
                        <th>{item?.inverterManufacturer || "-"}</th>
                        <th>{item?.monitoringCommunication || "-"}</th>
                        <th>{item?.panelManufacturer || "-"}</th>
                        <th>{item?.partialOrFullBackup || "-"}</th>
                        <th>{item?.propertyTrustName || "-"}</th>
                        <th>{item?.ptoLetterDate || "-"}</th>
                        <th>{item?.residentialCustomerType || "-"}</th>
                        {/* <th>{item?.storageOnly || "-"}</th> */}
                        <th>{item?.annualDegradationPercentage || "-"}</th>
                        <th>{item?.batteryKwSize || "-"}</th>
                        <th>{item?.sowAndPpaApprovalDate || "-"}</th>
                        <th>{item?.currentPpaSignedDate || "-"}</th>
                        <th>{item?.powerCapacityRating || "0"}</th>
                        {/* Special styled fields grouped at the end */}
                        <th>{item?.entityName || "-"}</th>
                        <th>{item?.clientName || "-"}</th>
                        <th>{item?.trancheSold || "-"}</th>
                        <th>{item?.billOfSaleSigned || "-"}</th>
                        <th style={{ width: "3rem" }}>
                          <MdDelete
                            onClick={() => handleDelete(item?._id)}
                            style={deleteStyles}
                          />
                          <FaEdit
                            onClick={() => handleEdit(item)}
                            style={EditStyles}
                          />
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-lg-flex justify-content-between">
                <Nav>
                  <Pagination className="mb-2 mb-lg-0">
                    <Pagination.Prev
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Pagination.Prev>
                    {showPaginationNumbers && (
                      <>
                        {currentPage > 4 && (
                          <>
                            <Pagination.Item onClick={() => setCurrentPage(1)}>
                              1
                            </Pagination.Item>
                            <Pagination.Ellipsis disabled />
                          </>
                        )}
                        {[...Array(totalPages)].map((_, page) => {
                          if(showPageNumbers(page, currentPage, totalPages)){
                            return (
                              <Pagination.Item
                                key={page + 1}
                                active={page + 1 === currentPage}
                                onClick={() => setCurrentPage(page + 1)}
                              >
                                {page + 1}
                              </Pagination.Item>
                            );
                          }
                          return null;
                        })}
                        {currentPage < totalPages - 3 && (
                          <>
                            <Pagination.Ellipsis disabled />
                            <Pagination.Item
                              onClick={() => setCurrentPage(totalPages)}
                            >
                              {totalPages}
                            </Pagination.Item>
                          </>
                        )}
                      </>
                    )}
                    <Pagination.Next
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(totalDocs / itemsPerPage)
                          )
                        )
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Pagination.Next>
                  </Pagination>
                </Nav>
              </Card.Footer>
            </Card.Body>
          </Card>
        </div>
      )}
      {isEditClicked && (
        <AssetRowPopup
          rowData={rowData}
          setIsEditClicked={setIsEditClicked}
          fetchAssetsData={fetchAssetsData}
        />
      )}
    </>
  );
};

export default AssetsTable;