import React from "react";
import styles from "../AccessForm/AccessForm.module.css";

const PermissionToggle = ({ permission, isChecked, onChange, disabled }) => {
  return (
    <div className={styles.permissionRow}>
      <div className={styles.permissionLabel}>
        {permission.charAt(0).toUpperCase() + permission.slice(1)}
      </div>
      <div className={styles.permissionToggleContainer}>
        <input
          type="checkbox"
          id={`toggle-${permission}`}
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
          style={{ display: 'none' }}
        />
        <label
          htmlFor={`toggle-${permission}`}
          className={`${styles.toggleLabel} ${isChecked ? styles.active : ''}`}
        >
          <span className={styles.toggleHandle}></span>
          <span className={styles.toggleText}>
            {isChecked ? 'ON' : 'OFF'}
          </span>
        </label>
      </div>
    </div>
  );
};

export default PermissionToggle;