export const productionReportRegisterNameValues = [
  "createdAt",
  "updatedAt",
  "recordId",
  "recordIdDeal",
  "assetId",  // Moved up in the order
  "dealName",
  "monthYear",
  "measurementType",
  "actualEstimatedPercent",
  "actualProduction",
  "netEstimate"
];

export const productionReportHeaderFields = [
  "Created At",
  "Updated At",
  "Record ID",
  "Record ID (Deal)",
  "Asset ID",  // Moved up in the order
  "Deal Name",
  "Month-Year",
  "Measurement Type",
  "Actual / Estimated %",
  "Actual Production",
  "Net Estimate",
  "Action"
];

export const productionReportDisabledFields = [
  "Created At",
  "Updated At",
  "Record ID",
  "Record ID (Deal)",
  "Asset ID"  // Added to disabled fields
];

export const productionReportTextFields = [
  "Record ID",
  "Record ID (Deal)",
  "Deal Name",
  "Month-Year",
  "Measurement Type",
  "Asset ID"
];