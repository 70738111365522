import React from "react";
import { Modal, Button } from "react-bootstrap";
import { deleteAdminTask } from "../../service/api.js";
import { showNotification } from "../../service/showNotification";

const DeleteTaskModal = ({ show, onClose, taskId, onDeleteSuccess }) => {
  const handleDelete = async () => {
    if (!taskId) return;
    
    try {
      await deleteAdminTask(taskId);
      showNotification("success", "Task deleted successfully");
  
      if (onDeleteSuccess) onDeleteSuccess(taskId); 
      onClose();
    } catch (error) {
      showNotification("error", "Failed to delete task");
    }
  };
  

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this task? This action cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTaskModal;