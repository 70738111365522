import React from 'react';
import AccessForm from '../../components/AccessForm'

export default function AccessPage() {
  return (
    <>
    <AccessForm/>
    </>
  )
}
