import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  productionReportHeaderFields,
  productionReportRegisterNameValues,
  productionReportTextFields,
  productionReportDisabledFields
} from "./ProductionReportFieldsArray";
import { updateProductionReportRow } from "../../service/api";
import { showNotification } from "../../service/showNotification";
import { productionReportSchema } from "../../yup"; // Import the schema

const excludedEditFields = ["Record ID", "Record ID (Deal)", "Asset ID"];

export const ProductionReportRowPopup = ({
  rowData,
  setIsEditClicked,
  fetchProductionReportsData,
}) => {
  const { 
    register, 
    handleSubmit, 
    reset, 
    formState: { errors } 
  } = useForm({
    resolver: yupResolver(productionReportSchema)
  });
  
  const [rowId, setRowId] = useState("");

  const onSubmit = async (values) => {
    try {
      // Add the excluded fields back to the values before sending
      const completeValues = {
        ...values,
        recordId: rowData.recordId,
        recordIdDeal: rowData.recordIdDeal,
        assetId: rowData.assetId
      };
      
      // Remove _id from the values before sending
      const { _id, ...updateValues } = completeValues;
      
      await updateProductionReportRow(rowId, updateValues);
      showNotification("success", "Report updated successfully");
      fetchProductionReportsData();
    } catch (error) {
      console.error("Error updating report:", error);
      showNotification("error", "Something went wrong");
    } finally {
      setIsEditClicked(false);
    }
  };

  useEffect(() => {
    if (rowData && Object.keys(rowData).length) {
      setRowId(rowData?._id);
      
      // Only reset with editable fields
      reset({
        dealName: rowData?.dealName,
        monthYear: rowData?.monthYear,
        measurementType: rowData?.measurementType,
        actualEstimatedPercent: rowData?.actualEstimatedPercent,
        actualProduction: rowData?.actualProduction,
        netEstimate: rowData?.netEstimate
      });
    }
  }, [rowData, reset]);

  return (
    <div className="overlay" onClick={() => setIsEditClicked(false)}>
      <div 
        className="overlayContent" 
        onClick={(e) => e.stopPropagation()}
        style={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          padding: '24px',
          width: '500px',
          maxWidth: '90vw',
          position: 'relative'
        }}
      >
        <span 
          className="closeBtn" 
          onClick={() => setIsEditClicked(false)}
          style={{
            position: 'absolute',
            top: '12px',
            right: '16px',
            fontSize: '24px',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
        >
          &times;
        </span>
        
        <h4 style={{ marginBottom: '20px', fontWeight: '600' }}>
          Update Production Report Details
        </h4>
        
        {/* Display the non-editable fields as read-only information */}
        <div className="non-editable-fields" style={{ marginBottom: '20px', padding: '12px', backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <h5 style={{ fontSize: '14px', marginBottom: '10px', fontWeight: '600' }}>Report Information</h5>
          <div style={{ marginBottom: '8px' }}>
            <span style={{ fontWeight: '500' }}>Record ID:</span> {rowData?.recordId || "-"}
          </div>
          <div style={{ marginBottom: '8px' }}>
            <span style={{ fontWeight: '500' }}>Record ID (Deal):</span> {rowData?.recordIdDeal || "-"}
          </div>
          <div style={{ marginBottom: '8px' }}>
            <span style={{ fontWeight: '500' }}>Asset ID:</span> {rowData?.assetId || "-"}
          </div>
        </div>
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-container" style={{ display: 'grid', gap: '16px' }}>
            {productionReportHeaderFields
              .filter(field => 
                !excludedEditFields.includes(field) && 
                field !== "Action" && 
                field !== "Created At" && 
                field !== "Updated At" && 
                field !== "Report ID"
              )
              .map((field) => {
                // Find the index in the original array
                const originalIndex = productionReportHeaderFields.findIndex(h => h === field);
                const registerName = productionReportRegisterNameValues[originalIndex];
                const inputType = productionReportTextFields.includes(field) ? "text" : "number";
                
                return (
                  <div key={field} style={{ marginBottom: '12px' }}>
                    <label 
                      htmlFor={registerName}
                      style={{ 
                        display: 'block', 
                        marginBottom: '6px', 
                        fontWeight: '500',
                        fontSize: '14px' 
                      }}
                    >
                      {field}
                    </label>
                    <input
                      id={registerName}
                      {...register(registerName)}
                      style={{
                        width: '100%',
                        padding: '8px 12px',
                        borderRadius: '4px',
                        border: '1px solid #ced4da',
                        fontSize: '14px'
                      }}
                      step="any"
                      placeholder={`Enter ${field}`}
                      type={inputType}
                    />
                    {/* Display validation errors */}
                    {errors[registerName] && (
                      <p style={{ 
                        color: 'red', 
                        fontSize: '12px', 
                        marginTop: '4px',
                        marginBottom: '0' 
                      }}>
                        {errors[registerName]?.message}
                      </p>
                    )}
                  </div>
                );
              })}
          </div>
          
          <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              type="submit"
              style={{
                backgroundColor: '#356C58',
                border: 'none',
                padding: '8px 16px'
              }}
            >
              Update Report
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};