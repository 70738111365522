import React, { useState, useCallback, memo } from "react";
import { headerFields, specialStylingFields } from "./FieldsArray";
import { Table } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { ButtonLoader } from "../ButtonLoader";

const TableRow = memo(
  ({
    item,
    handleConfirm,
    handleRejectClick,
    loadingStates,
    loadingRejectState,
  }) => {
    return (
      <tr key={item?.recordId}>
        <th>{item?.prodManagedBy || "HDM"}</th>
        <th>{item?.recordId || "-"}</th>
        <th>{item?.dealName || "-"}</th>
        <th>{item?.assetOwner || "-"}</th>
        <th>{item?.assetStage || "-"}</th>
        <th>{item?.financingOption || "-"}</th>
        <th>{item?.systemSize || "-"}</th>
        <th>{item?.ppaTerm || "-"}</th>
        <th>{item?.epcAmount || "-"}</th>
        <th>{item?.deposit || "-"}</th>
        <th>{item?.inceptionPurchasePrice || "-"}</th>
        <th>{item?.inceptionEquityContribution || "-"}</th>
        <th>{item?.investorPurchasePrice || "-"}</th>
        <th>{item?.investorEquityContribution || "-"}</th>
        <th>{item?.inceptionFinalMarkupPercentage || "-"}</th>
        <th>{item?.inceptionFinalMarkup || "-"}</th>
        <th>{item?.developerPayment || "-"}</th>
        <th>{item?.developerMarkupCheck || "-"}</th>
        <th>{item?.inverterWarranty || "-"}</th>
        <th>{item?.investorPurchasePriceFinal || "-"}</th>
        <th>{item?.investorEquityContributionFinal || "-"}</th>
        <th>{item?.adminFee || "-"}</th>
        <th>{item?.costPerKwh || "-"}</th>
        <th>{item?.trancheTitle || "-"}</th>
        <th>{item?.dealSource || "-"}</th>
        <th>{item?.assetType || "-"}</th>
        <th>{item?.year1Production || "-"}</th>
        <th>{item?.productionGuarantee || "-"}</th>
        <th>{item?.electricityOffsetPercentage || "-"}</th>
        <th>{item?.fullName || "-"}</th>
        <th>{item?.dealType || "-"}</th>
        <th>{item?.installCompletedDate || "-"}</th>
        <th>{item?.m1CertificateSignDate || "-"}</th>
        <th>{item?.energizedDate || "-"}</th>
        <th>{item?.prePaidPercentageOfBillOfSale || "-"}</th>
        <th>{item?.m2CertificateSignedDate || "-"}</th>
        <th>{item?.moduleManufacturerAVL || "-"}</th>
        <th>{item?.inverterManufacturerAVL || "-"}</th>
        <th>{item?.recordNumber || "-"}</th>
        <th>{item?.assetStreetAddress || "-"}</th>
        <th>{item?.assetCity || "-"}</th>
        <th>{item?.assetState || "-"}</th>
        <th>{item?.assetZipCode || "-"}</th>
        <th>{item?.initialTerm || "-"}</th>
        <th>{item?.ppaRate || "-"}</th>
        <th>{item?.estimatedExtendedWarrantyCost || "-"}</th>
        <th>{item?.solarPrepaid || "-"}</th>
        <th>{item?.batterySalesPrice || "-"}</th>
        <th>{item?.batteryPrepaid || "-"}</th>
        <th>{item?.billOfSaleAmount || "-"}</th>
        <th>{item?.censusTractId || "-"}</th>
        <th>{item?.costPerMonth || "-"}</th>
        <th>{item?.customerCounty || "-"}</th>
        <th>{item?.dealOwner || "-"}</th>
        <th>{item?.energyCommunityDescription || "-"}</th>
        <th>{item?.energyCommunityTaxEligible || "-"}</th>
        <th>{item?.energyCommunityTitle || "-"}</th>
        <th>{item?.energyCommunityType || "-"}</th>
        <th>{item?.entityType || "-"}</th>
        <th>{item?.installerCertifiedToInstallBatteries || "-"}</th>
        <th>{item?.inverterManufacturer || "-"}</th>
        <th>{item?.monitoringCommunication || "-"}</th>
        <th>{item?.panelManufacturer || "-"}</th>
        <th>{item?.partialOrFullBackup || "-"}</th>
        <th>{item?.propertyTrustName || "-"}</th>
        <th>{item?.ptoLetterDate || "-"}</th>
        <th>{item?.residentialCustomerType || "-"}</th>
        {/* <th>{item?.storageOnly || "-"}</th> */}
        <th>{item?.annualDegradationPercentage || "-"}</th>
        <th>{item?.batteryKwSize || "-"}</th>
        <th>{item?.sowAndPpaApprovalDate || "-"}</th>
        <th>{item?.currentPpaSignedDate || "-"}</th>
        <th>{item?.powerCapacityRating || "0"}</th>
        {/* Special styled fields grouped at the end */}
        <th>{item?.entityName || "-"}</th>
        <th>{item?.clientName || "-"}</th>
        <th>{item?.trancheSold || "-"}</th>
        <th>{item?.billOfSaleSigned || "-"}</th>
        <th
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {loadingStates[item.recordId] ? (
            <ButtonLoader show={true} />
          ) : (
            <TiTick onClick={() => handleConfirm(item)} style={EditStyles} />
          )}
          {loadingRejectState[item.recordId] ? (
            <ButtonLoader show={true} />
          ) : (
            <ImCross
              onClick={() => handleRejectClick(item)}
              style={deleteStyles}
            />
          )}
        </th>
      </tr>
    );
  }
);

const DuplicateAssetTable = ({ duplicate, onConfirm, handleReject }) => {
  const [loadingStates, setLoadingStates] = useState({});
  const [loadingRejectState, setLoadingRejectState] = useState({});

  // Style for special header fields
  const specialHeaderStyle = {
    color: "#356C58", // Dark green color for text (same as the edit button)
    fontWeight: "bold",
  };

  // Function to check if a header needs special styling
  const isSpecialHeader = (header) => {
    return specialStylingFields.includes(header);
  };

  const handleConfirm = useCallback(
    async (item) => {
      setLoadingStates((prev) => ({ ...prev, [item.recordId]: true }));
      try {
        await onConfirm([item], true);
      } finally {
        setLoadingStates((prev) => ({ ...prev, [item.recordId]: false }));
      }
    },
    [onConfirm]
  );

  const handleRejectClick = useCallback(
    async (item) => {
      setLoadingRejectState((prev) => ({ ...prev, [item.recordId]: true }));
      try {
        await handleReject(item.recordId);
      } finally {
        setLoadingRejectState((prev) => ({ ...prev, [item.recordId]: false }));
      }
    },
    [handleReject]
  );

  return (
    <div className="scrollable-table-container3 mx-3">
      <Table hover className="user-table align-items-center min-w-full">
        <thead className="stickyHeader">
          <tr>
            {headerFields
              .filter(
                (header) =>
                  !["Status", "Created At", "Updated At", "Asset Id"].includes(
                    header
                  )
              )
              .map((header, index) => {
                const isSortApplied =
                  header === "Deal Name" || header === "Tranche Title";

                // Apply special styling for the specified header fields
                const headerStyle = isSpecialHeader(header)
                  ? specialHeaderStyle
                  : {};

                return isSortApplied ? (
                  <th
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "14px 24px",
                      ...(isSpecialHeader(header) ? specialHeaderStyle : {}),
                    }}
                  >
                    {header}
                  </th>
                ) : (
                  <th key={index} style={headerStyle}>
                    {header}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {duplicate?.map((item) => (
            <TableRow
              key={item.recordId}
              item={item}
              handleConfirm={handleConfirm}
              handleRejectClick={handleRejectClick}
              loadingStates={loadingStates}
              loadingRejectState={loadingRejectState}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const deleteStyles = {
  color: "red",
  fontSize: "1.2rem",
  cursor: "pointer",
};
const EditStyles = {
  color: "green",
  fontSize: "2rem",
  cursor: "pointer",
};

export default DuplicateAssetTable;
