import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUserPermission } from '../service/api';
import Preloader from '../components/Preloader';

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const fetchPermissions = async () => {
        setIsLoading(true);
        try {
            const permission = await getUserPermission();
            setIsAdmin(permission.data.isAdmin);
            setPermissions(permission.data.permission);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchPermissions(); 
    }, []);

    const hasPermission = (permission) => {
        // return true
        return isAdmin || !!permissions.includes(permission);
    };

    const resetPermissions = () => {
        setIsAdmin(false);
        setPermissions([]);
    };

    return (
        <PermissionContext.Provider value={{ isLoading, permissions,fetchPermissions, hasPermission,resetPermissions }}>
            {isLoading ? <Preloader show={true} /> : children}
        </PermissionContext.Provider>
    );
};

export const usePermissions = () => {
    return useContext(PermissionContext);
};
