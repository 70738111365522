import React from "react";
import styles from "../AccessForm/AccessForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const TaskList = ({
  navItems,
  selectedNavItem,
  onNavItemSelect,
  onDeleteClick,
}) => {
  return (
    <div className={styles.left}>
      <div className={styles.leftHeader}>
        <h3>Tasks</h3>
      </div>
      <div className={styles.navItemsContainer}>
        <div className={styles.navItems}>
          {navItems.map((item, index) => (
            <div
              key={index}
              className={`
                ${styles.navItem} 
                ${
                  selectedNavItem?.title === item?.title
                    ? styles.selectedNavItem
                    : ""
                }
              `}
              onClick={() => onNavItemSelect(item)}
            >
              <span className={styles.navItemText}>{item?.title}</span>
              <FontAwesomeIcon
                icon={faTrash}
                className={styles.deleteIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteClick(item?.task);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaskList;
