import React, { useCallback, useEffect, useState } from "react";
import { Card, Nav, Pagination, Table } from "react-bootstrap";
import { TableSkeleton } from "../../skeleton/TableSkeleton";
import {
  deleteProductionReport,
  getAllProductionReportsData,
} from "../../service/api";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { showNotification } from "../../service/showNotification";
import { ProductionReportRowPopup } from "./ProductionReportRowPopup";
import { productionReportHeaderFields } from "./ProductionReportFieldsArray";
import { showPageNumbers } from "../../utils/table";
import { formatDate } from "../../utils/formatDate";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const ProductionReportTable = ({
  isNewUploaded,
  searchTerm,
  setTotalReports,
}) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(10);
  const [totalPages, setTotalPages] = useState(10);
  const [rowData, setRowData] = useState({});
  const [isEditClicked, setIsEditClicked] = useState(false);
  // New state for delete confirmation
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const itemsPerPage = 10;
  const showPaginationNumbers = totalPages > 10;

  const deleteStyles = {
    marginRight: "10px",
    color: "red",
    fontSize: "1.2rem",
    cursor: "pointer",
  };

  const EditStyles = {
    color: "green",
    fontSize: "1.2rem",
    cursor: "pointer",
  };

  const fetchProductionReportsData = useCallback(async () => {
    try {
      setLoading(true);
      // Removed sorting parameters
      const response = await getAllProductionReportsData(
        currentPage,
        itemsPerPage,
        searchTerm
      );
      setReports(response.data.report);
      const totalPages = Math.ceil(response.data.totalDocs / itemsPerPage);
      setTotalDocs(response.data.totalDocs);
      setTotalPages(totalPages);
      setTotalReports(response.data.totalDocs);
    } catch (error) {
      console.log(error);
      showNotification("error", "Failed to fetch production reports");
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm, setTotalReports]);

  // Modified to show confirmation modal
  const promptDelete = (id) => {
    setReportToDelete(id);
    setDeleteModalOpen(true);
  };

  // Actual delete function
  const handleDeleteConfirm = async () => {
    if (!reportToDelete) return;

    try {
      setDeleteLoading(true);
      await deleteProductionReport(reportToDelete);
      showNotification("success", "Report deleted successfully");
      fetchProductionReportsData();
    } catch (error) {
      showNotification("error", "Something went wrong");
    } finally {
      setDeleteLoading(false);
      setDeleteModalOpen(false);
      setReportToDelete(null);
    }
  };

  const handleEdit = async (item) => {
    setRowData(item);
    setIsEditClicked(true);
  };

  useEffect(() => {
    fetchProductionReportsData();
  }, [currentPage, isNewUploaded, fetchProductionReportsData, searchTerm]);

  return (
    <>
      {loading ? (
        <TableSkeleton />
      ) : (
        <div className="scrollable-table-container2 mx-3">
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    {productionReportHeaderFields
                      .filter((header) => header !== "Report ID") // Remove Report ID from headers
                      .map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {reports?.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{formatDate(new Date(item?.createdAt)) || ""}</td>
                        <td>{formatDate(new Date(item?.updatedAt)) || ""}</td>
                        <td className="assetInfo" title={item?.recordId || ""}>
                          Info
                        </td>
                        <td
                          className="assetInfo"
                          title={item?.recordIdDeal || ""}
                        >
                          Info
                        </td>
                        <td>{item?.assetId || "-"}</td>{" "}
                        <td>{item?.dealName || "-"}</td>
                        <td>{item?.monthYear || "-"}</td>
                        <td>{item?.measurementType || "-"}</td>
                        <td>{item?.actualEstimatedPercent }</td>
                        <td>{item?.actualProduction }</td>
                        <td>{item?.netEstimate}</td>
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdDelete
                              onClick={() => promptDelete(item?._id)}
                              style={deleteStyles}
                            />
                            <FaEdit
                              onClick={() => handleEdit(item)}
                              style={EditStyles}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-lg-flex justify-content-between">
                <Nav>
                  <Pagination className="mb-2 mb-lg-0">
                    <Pagination.Prev
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Pagination.Prev>
                    {showPaginationNumbers && (
                      <>
                        {currentPage > 4 && (
                          <>
                            <Pagination.Item onClick={() => setCurrentPage(1)}>
                              1
                            </Pagination.Item>
                            <Pagination.Ellipsis disabled />
                          </>
                        )}
                        {[...Array(totalPages)].map((_, page) => {
                          if (showPageNumbers(page, currentPage, totalPages)) {
                            return (
                              <Pagination.Item
                                key={page + 1}
                                active={page + 1 === currentPage}
                                onClick={() => setCurrentPage(page + 1)}
                              >
                                {page + 1}
                              </Pagination.Item>
                            );
                          }
                          return null;
                        })}
                        {currentPage < totalPages - 3 && (
                          <>
                            <Pagination.Ellipsis disabled />
                            <Pagination.Item
                              onClick={() => setCurrentPage(totalPages)}
                            >
                              {totalPages}
                            </Pagination.Item>
                          </>
                        )}
                      </>
                    )}
                    <Pagination.Next
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(totalDocs / itemsPerPage)
                          )
                        )
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Pagination.Next>
                  </Pagination>
                </Nav>
              </Card.Footer>
            </Card.Body>
          </Card>
        </div>
      )}
      {isEditClicked && (
        <ProductionReportRowPopup
          rowData={rowData}
          setIsEditClicked={setIsEditClicked}
          fetchProductionReportsData={fetchProductionReportsData}
        />
      )}

      {/* Delete confirmation modal */}
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        isLoading={deleteLoading}
      />
    </>
  );
};

export default ProductionReportTable;
