import React, { useState, useEffect } from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { createAdminTask, getAllAdminTasks } from "../../service/api";
import "./AddTaskModal.module.css";
import { showNotification } from "../../service/showNotification";

const initialTaskOptions = [
  { value: "SubAdmin List", label: "SubAdmin List" },
  { value: "Customization Section", label: "Customization Section" },
  { value: "Report List", label: "Report List" },
  { value: "Project Input", label: "Project Input" },
  { value: "Entity Table", label: "Entity Table" },
  { value: "Proforma Backend", label: "Proforma Backend" },
  { value: "New Reports", label: "New Reports" },
  { value: "NDA Form", label: "NDA Form" },
  { value: "PURL Journey", label: "PURL Journey" },
  { value: "Proforma Users", label: "Proforma Users" },
  { value: "Contact Owner", label: "Contact Owner" },
  { value: "Assets", label: "Assets" },
  { value: "Production Reporting", label: "Production Reporting" }
];


const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    padding: "3px",
    width: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "120px", 
  }),
  menu: (provided) => ({
    ...provided,
    overflow: "hidden", 
  }),
};

export const AddTaskModal = ({ show, onClose, onAddSuccess }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [loading, setLoading] = useState(false);
  const [availableTaskOptions, setAvailableTaskOptions] = useState([]);
  const [fetchingTasks, setFetchingTasks] = useState(false);

 
  useEffect(() => {
    if (show) {
      fetchAvailableTasks();
    } else {
      setSelectedTask(null); 
    }
  }, [show]);

  const fetchAvailableTasks = async () => {
    setFetchingTasks(true);
    try {
      const existingTasks = await getAllAdminTasks();
      
     
      const existingTaskNames = existingTasks.data.data.map(task => task.name);
      
     
      const filteredOptions = initialTaskOptions.filter(
        option => !existingTaskNames.includes(option.value)
      );
      
      setAvailableTaskOptions(filteredOptions);
    } catch (error) {
      showNotification("error", "Failed to fetch existing tasks");
      setAvailableTaskOptions(initialTaskOptions); 
    } finally {
      setFetchingTasks(false);
    }
  };

  const handleSelectTask = (selectedOption) => {
    setSelectedTask(selectedOption);
  };

  const handleSubmit = async () => {
    if (!selectedTask) {
      showNotification("error", "Please select a valid task");
      return;
    }

    setLoading(true);
    try {
      await createAdminTask({ name: selectedTask.value });
      showNotification("success", "Task created successfully");
      onAddSuccess();
      onClose();
    } catch (error) {
      showNotification("error", "Failed to create task");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered className="custom-modal">
      <Modal.Header>
        <Modal.Title className="h6">Create Task</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        {fetchingTasks ? (
          <div className="text-center">Loading available tasks...</div>
        ) : availableTaskOptions.length === 0 ? (
          <div className="text-center">All tasks have already been created</div>
        ) : (
          <Select
            options={availableTaskOptions}
            value={selectedTask}
            onChange={handleSelectTask}
            placeholder="Search for a task..."
            styles={customSelectStyles}
            classNamePrefix="single-scrollbar-select"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="primary" 
          onClick={handleSubmit} 
          disabled={loading || fetchingTasks || availableTaskOptions.length === 0}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};