import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { Routes } from "../routes";
import { usePermissions } from "../context/PermissionContext";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import SubAdmin from "./SubAdmin.jsx";
import Settings from "./Settings";
import BootstrapTables from "./tables/Tables.jsx";
import Signin from "./Signin.jsx";
import ProfileSetup from "./auth/ProfileSetup.jsx";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";
import NewReports from "./newReport/NewReports.jsx";
import AccessPage from "./AccessPage/index.jsx";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ProjectInput from "../components/ProjectInput.jsx";
import Customization from "../components/Customization.jsx";
import NDAForm from "../components/NDAForm.jsx";
import EntityTable from "../components/EntityTable.jsx";
import { PURLJourney } from "../components/PurlJourney.jsx";
import { ContactOwner } from "../components/ContactOwner/index.jsx";
import AddProformaUser from "./proformaUser/AddProformaUser.jsx";
import AssetPage from "./AssetPage/index.jsx";
import ProductionReporting from "./ProductionReportPage/index.jsx";

const RouteWithLoader = ({ component: Component, permission, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { hasPermission } = usePermissions();
  
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        // Only check permission if one is specified
        if (permission && !hasPermission(permission)) {
          return <Redirect to="/dashboard/overview" />;
        }
        
        return (
          <>
            {" "}
            {rest.location?.pathname === "/new-reports/edit" ? (
              <Preloader show={loaded ? false : true} />
            ) : (
              <></>
            )}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

const RouteWithSidebar = ({ component: Component, permission, ...rest }) => {
  const { hasPermission } = usePermissions();
  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        // Only check permission if one is specified
        if (permission && !hasPermission(permission)) {
          return <Redirect to="/dashboard/overview" />;
        }
        
        return (
          <>
            <Sidebar />
            <main className="content">
              <Navbar />
              <Component {...props} />
              <Footer
                toggleSettings={toggleSettings}
                showSettings={showSettings}
              />
            </main>
          </>
        );
      }}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader
      exact
      path={Routes.ProfileSetup.path}
      component={ProfileSetup}
    />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />
    
    {/* Dashboard - might need to be accessible to everyone with a token */}
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />
    
    {/* Protected routes */}
    <RouteWithLoader
      exact
      path={Routes.Customization.path}
      component={Customization}
      permission="read:customization_section"
    />
    <RouteWithSidebar 
      exact 
      path={Routes.SubAdmin.path} 
      component={SubAdmin} 
      permission="read:subadmin_list"
    />
    <RouteWithSidebar
      exact
      path={Routes.Transactions.path}
      component={Transactions}
      permission="read:report_list"
    />
    <RouteWithSidebar 
      exact 
      path={Routes.Settings.path} 
      component={Settings}
    />
    <RouteWithSidebar
      exact
      path={Routes.BootstrapTables.path}
      component={BootstrapTables}
      permission="read:proforma_backend"
    />
    <RouteWithSidebar
      exact
      path={Routes.NewReports.path}
      component={NewReports}
      permission="read:new_reports"
    />
    <RouteWithSidebar
      exact
      path={Routes.EntityTable.path}
      component={EntityTable}
      permission="read:entity_table"
    />
    <RouteWithSidebar
      exact
      path={Routes.PURLJourney.path}
      component={PURLJourney}
      permission="read:purl_journey"
    />
    <RouteWithSidebar
      exact
      path={Routes.ContactOwner.path}
      component={ContactOwner}
      permission="read:contact_owner"
    />
    <RouteWithLoader
      exact
      path={Routes.ProjectInput.path}
      component={ProjectInput}
      permission="read:project_input"
    />
    <RouteWithSidebar
      exact
      path={Routes.ProformaUsers.path}
      component={AddProformaUser}
      permission="read:proforma_users"
    />
    <RouteWithSidebar 
      exact 
      path={Routes.Assets.path} 
      component={AssetPage} 
      permission="read:assets"
    />
    <RouteWithSidebar 
      exact 
      path={Routes.ProductionReporting.path} 
      component={ProductionReporting} 
      permission="read:production_reporting"
    />
    <RouteWithLoader 
      exact 
      path={Routes.NDAForm.path} 
      component={NDAForm} 
      permission="read:nda_form"
    />
    <RouteWithSidebar 
      exact 
      path={Routes.AccessControl.path} 
      component={AccessPage}
      permission="read:access_control"
    />

    {/* Default route */}
    {localStorage.getItem("token") ? (
      <Redirect to={Routes.DashboardOverview.path} />
    ) : (
      <Redirect to={Routes.Signin.path} />
    )}
  </Switch>
);