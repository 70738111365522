import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button } from "react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { showNotification } from "../../service/showNotification";
import ProductionReportTable from "../../components/ProductionReportTable";
import { uploadProductionReport } from "../../service/api";
import { ButtonLoader } from "../../components/ButtonLoader";
import { config } from "../../configs/index";
import useLocalStorage from "../../hooks/useLocalStorage";
import SpecialUploadPopup from "../../components/ProductionReportTable/SpecialUploadPopup";
const ProductionReportPage = () => {
  const fileInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [isNewUploaded, setIsNewUploaded] = useState(false);
  const [token, _] = useLocalStorage("token");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalReports, setTotalReports] = useState();
  const [isSpecialUploadModalOpen, setIsSpecialUploadModalOpen] =
    useState(false);

  const handleUpload = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const file = e.target?.files?.[0];

      const data = await uploadProductionReport(file);

      if (data.data.success) {
        showNotification("success", "Production Reports uploaded successfully");
        setIsNewUploaded(!isNewUploaded);
      } else {
        showNotification("error", "Failed to upload production reports");
      }
    } catch (error) {
      console.log("error", error.response?.data?.message);
      showNotification("error", error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleReportExport = async () => {
    setExportLoader(true);
    try {
      const response = await fetch(
        `${config.REACT_APP_BASE_URL}/api/admin/production-report/export`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "ProductionReports.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        throw new Error("Failed to generate the Excel file");
      }
    } catch (error) {
      console.error("Error during export:", error);
      showNotification("error", "Failed to download production reports");
    } finally {
      setExportLoader(false);
    }
  };

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block "
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent ",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Production Reports</Breadcrumb.Item>
          </Breadcrumb>
          <h4>
            Production Reports{" "}
            <span style={{ opacity: 0.7, fontSize: "1.2rem" }}>
              (Total Reports: {totalReports})
            </span>
          </h4>
        </div>
        <div className="search-container">
          <input
            type="string"
            className="mx-2"
            placeholder="Deal Name or Asset ID"
            style={{ padding: "10px 8px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <Button
            className="mx-2"
            onClick={handleReportExport}
            disabled={exportLoader}
          >
            {exportLoader ? <ButtonLoader show={true} /> : "Export"}
          </Button>
          <Button
            className="mx-2"
            onClick={() => fileInputRef.current.click()}
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader show={true} /> : "Upload"}
          </Button>
          <input
            onChange={handleUpload}
            multiple={false}
            ref={fileInputRef}
            type="file"
            accept=".csv"
            hidden
          />
          <Button
            className="mx-2 mt-1 mt-sm-0 mt-md-1 mt-lg-0"
            onClick={() => {
              setIsSpecialUploadModalOpen(true);
            }}
          >
            Special Upload
          </Button>
        </div>
      </div>

      <ProductionReportTable
        isNewUploaded={isNewUploaded}
        searchTerm={searchTerm}
        setTotalReports={setTotalReports}
      />
      {isSpecialUploadModalOpen && (
        <SpecialUploadPopup
          isSpecialUploadModalOpen={isSpecialUploadModalOpen}
          closeSpecialUploadModal={setIsSpecialUploadModalOpen}
          refreshTable={() => setIsNewUploaded(!isNewUploaded)}
        />
      )}
    </>
  );
};

export default ProductionReportPage;
