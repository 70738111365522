import React, { useState, useEffect } from "react";
import styles from "./AccessForm.module.css";
import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  getSubadminApi,
  getAllAdminTasks,
  getAdminPermissionByUserIdAndTaskId,
  deleteAdminTask,
} from "../../service/api";
import Header from "./Header";
import TaskList from "./TaskList";
import PermissionsPanel from "./PermissionsPanel";
import DeleteTaskModal from "./DeleteTaskModal";
import { showNotification } from "../../service/showNotification.js";

const AccessForm = () => {
  const [navItems, setNavItems] = useState([]);
  const [selectedSubAdmin, setSelectedSubAdmin] = useState(null);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [adminPermissionId, setAdminPermissionId] = useState(null);
  const [toggleState, setToggleState] = useState({
    read: false,
    create: false,
    update: false,
    delete: false,
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    fetchAdminTasks();
  }, []);

  const fetchAdminTasks = async () => {
    try {
      const response = await getAllAdminTasks();
      const tasks = response?.data?.data || [];
      const options = tasks.map((task) => ({
        title: task.name,
        task: task._id,
      }));
      setNavItems(options);
      if (options?.length > 0) {
        setSelectedNavItem(options[0]);
      }
    } catch (error) {
      console.error("Error fetching Admin Tasks:", error);
    }
  };

  const fetchPermissions = async (userId, taskId) => {
    try {
      const response = await getAdminPermissionByUserIdAndTaskId(
        userId,
        taskId
      );
      const permissionData = response.data.data;

      if (response.data.success && Object.keys(permissionData).length > 0) {
        setToggleState(permissionData.permissions);
        setAdminPermissionId(permissionData._id);
      } else {
        resetPermissions();
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
      resetPermissions();
    }
  };

  const resetPermissions = () => {
    setAdminPermissionId(null);
    setToggleState({
      create: false,
      read: false,
      update: false,
      delete: false,
    });
  };

  const handleNavItemSelect = async (item) => {
    if (!selectedSubAdmin) return;

    setSelectedNavItem(item);
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await deleteAdminTask(taskId);
      showNotification("success", "Task deleted successfully");

      setNavItems((prevNavItems) =>
        prevNavItems.filter((item) => item.task !== taskId)
      );

      setIsDeleteModalOpen(false);
      setSelectedNavItem(null);
    } catch (error) {
      showNotification("error", "Failed to delete task");
    }
  };

  const removeTaskFromList = (taskId) => {
    setNavItems((prevNavItems) =>
      prevNavItems.filter((item) => item.task !== taskId)
    );
  };

  useEffect(() => {
    selectedSubAdmin &&
      selectedNavItem &&
      fetchPermissions(selectedSubAdmin?.value, selectedNavItem.task);
  }, [selectedSubAdmin, selectedNavItem]);

  return (
    <div className={styles.container}>
      {/* Header Section */}
      <div className={styles.header}>
        <div className={styles.breadcrumbContainer}>
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Access Control</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Header
          fetchAdminTasks={fetchAdminTasks}
          selectedSubAdmin={selectedSubAdmin}
          setSelectedSubAdmin={setSelectedSubAdmin}
        />
      </div>

      <div className={styles.content}>
        <TaskList
          navItems={navItems}
          selectedNavItem={selectedNavItem}
          onNavItemSelect={handleNavItemSelect}
          onDeleteClick={handleDeleteTask}
        />

        <PermissionsPanel
          toggleState={toggleState}
          selectedSubAdmin={selectedSubAdmin}
          selectedNavItem={selectedNavItem}
          adminPermissionId={adminPermissionId}
          onToggleChange={async (updatedToggleState) => {
            setToggleState(updatedToggleState);
          }}
        />
      </div>

      <DeleteTaskModal
        show={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setSelectedNavItem(null);
        }}
        taskId={selectedNavItem?.task}
        onDeleteSuccess={removeTaskFromList}
      />
    </div>
  );
};

export default AccessForm;
